import React, { useContext, useEffect, useState } from 'react';
import { Box, Breadcrumbs, Grid, Typography, IconButton, useTheme } from '@material-ui/core';
import { UserContext } from '../../components/UserContext';
import NavLink from '../../components/displayValues/NavLink';
import { useSnackbar } from 'notistack';
import CopyToClipboard from 'react-copy-to-clipboard';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  FileCopyOutlined,
} from '@material-ui/icons';
import { QRCodeCanvas } from 'qrcode.react';

function Marketing(props) {
  const theme = useTheme();
  const { orgPublicId, authenticatedUser } = useContext(UserContext);
  const [copied, setCopied] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {
    match: { params },
  } = props;
  const donatePageUrl = `${process.env.REACT_APP_GOODBRICKS_PUBLIC_URL}/donate/${orgPublicId}/${params.slug}`;
  const causePageUrl = `${process.env.REACT_APP_GOODBRICKS_PUBLIC_URL}/cause/${orgPublicId}/${params.slug}`;
  const campaignPageUrl = `${process.env.REACT_APP_GOODBRICKS_PUBLIC_URL}/campaign/${orgPublicId}/${params.slug}`;
  const pledgePageUrl = `${process.env.REACT_APP_GOODBRICKS_PUBLIC_URL}/pledge/${orgPublicId}/${params.slug}`;
  const statsPageUrl = `${process.env.REACT_APP_GOODBRICKS_PUBLIC_URL}/stats/${orgPublicId}/${params.slug}`;
  return (
    <React.Fragment>
      <Box my={9}>
        <Breadcrumbs aria-label="Breadcrumb">
          <NavLink href="/causes" text="Causes" color="primary" />
          {<Typography component="span">Markerting</Typography>}
        </Breadcrumbs>
      </Box>
      <Grid container spacing={6}>
        <Grid container item spacing={4}>
          <Grid item>
            <a target="_blank" href={donatePageUrl} color="primary">
              Donate Page URL
            </a>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              value={donatePageUrl}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <CopyToClipboard
                      text={donatePageUrl}
                      onCopy={() => {
                        setCopied(true);
                        enqueueSnackbar('Copied Campaign URL', {
                          variant: 'success',
                          anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                          },
                        });
                      }}>
                      <IconButton style={{ color: theme.palette.grey[600] }}>
                        <FileCopyOutlined fontSize="small" color="inherit" style={{ marginTop: '0.3rem' }} />
                      </IconButton>
                    </CopyToClipboard>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <QRCodeCanvas value={donatePageUrl}
                          size={512}
                          imageSettings={{
                            src: "http://admin.goodbricks.org/GB.png", // Logo Image URL
                            x: undefined,
                            y: undefined,
                            height: 96, // Adjust based on logo size
                            width: 96, // Adjust based on logo size
                            excavate: true, // Ensure QR code remains scannable
                          }}
            />,
          </Grid>
        </Grid>

        <Grid container item spacing={4}>
          <Grid item>
            <a target="_blank" href={causePageUrl} color="primary">
              Cause Page URL
            </a>
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              value={causePageUrl}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <CopyToClipboard
                      text={causePageUrl}
                      onCopy={() => {
                        setCopied(true);
                        enqueueSnackbar('Copied Campaign URL', {
                          variant: 'success',
                          anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                          },
                        });
                      }}>
                      <IconButton style={{ color: theme.palette.grey[600] }}>
                        <FileCopyOutlined fontSize="small" color="inherit" style={{ marginTop: '0.3rem' }} />
                      </IconButton>
                    </CopyToClipboard>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <QRCodeCanvas value={causePageUrl}
                          size={512}
                          imageSettings={{
                            src: "http://admin.goodbricks.org/GB.png", // Logo Image URL
                            x: undefined,
                            y: undefined,
                            height: 96, // Adjust based on logo size
                            width: 96, // Adjust based on logo size
                            excavate: true, // Ensure QR code remains scannable
                          }}
            />,
          </Grid>
        </Grid>
        <Grid container item spacing={4}>
          <Grid item>
            <a target="_blank" href={campaignPageUrl} color="primary">
              Campaign Page URL
            </a>
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              value={campaignPageUrl}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <CopyToClipboard
                      text={campaignPageUrl}
                      onCopy={() => {
                        setCopied(true);
                        enqueueSnackbar('Copied Campaign URL', {
                          variant: 'success',
                          anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                          },
                        });
                      }}>
                      <IconButton style={{ color: theme.palette.grey[600] }}>
                        <FileCopyOutlined fontSize="small" color="inherit" style={{ marginTop: '0.3rem' }} />
                      </IconButton>
                    </CopyToClipboard>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <QRCodeCanvas value={campaignPageUrl}
                          size={512}
                          imageSettings={{
                            src: "http://admin.goodbricks.org/GB.png", // Logo Image URL
                            x: undefined,
                            y: undefined,
                            height: 96, // Adjust based on logo size
                            width: 96, // Adjust based on logo size
                            excavate: true, // Ensure QR code remains scannable
                          }}
            />,
          </Grid>
        </Grid>
        <Grid container item spacing={4}>
          <Grid item>
            <a target="_blank" href={pledgePageUrl} color="primary">
              Pledge Page URL
            </a>
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              value={pledgePageUrl}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <CopyToClipboard
                      text={pledgePageUrl}
                      onCopy={() => {
                        setCopied(true);
                        enqueueSnackbar('Copied Campaign URL', {
                          variant: 'success',
                          anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                          },
                        });
                      }}>
                      <IconButton style={{ color: theme.palette.grey[600] }}>
                        <FileCopyOutlined fontSize="small" color="inherit" style={{ marginTop: '0.3rem' }} />
                      </IconButton>
                    </CopyToClipboard>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <QRCodeCanvas value={pledgePageUrl}
                          size={512}
                          imageSettings={{
                            src: "http://admin.goodbricks.org/GB.png", // Logo Image URL
                            x: undefined,
                            y: undefined,
                            height: 96, // Adjust based on logo size
                            width: 96, // Adjust based on logo size
                            excavate: true, // Ensure QR code remains scannable
                          }}
            />,
          </Grid>
        </Grid>
        <Grid container item spacing={4}>
          <Grid item>
            <a target="_blank" href={statsPageUrl} color="primary">
              Stats Page URL
            </a>
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              value={statsPageUrl}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <CopyToClipboard
                      text={statsPageUrl}
                      onCopy={() => {
                        setCopied(true);
                        enqueueSnackbar('Copied Campaign URL', {
                          variant: 'success',
                          anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'center',
                          },
                        });
                      }}>
                      <IconButton style={{ color: theme.palette.grey[600] }}>
                        <FileCopyOutlined fontSize="small" color="inherit" style={{ marginTop: '0.3rem' }} />
                      </IconButton>
                    </CopyToClipboard>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <QRCodeCanvas value={statsPageUrl}
                          size={512}
                          imageSettings={{
                            src: "http://admin.goodbricks.org/GB.png", // Logo Image URL
                            x: undefined,
                            y: undefined,
                            height: 96, // Adjust based on logo size
                            width: 96, // Adjust based on logo size
                            excavate: true, // Ensure QR code remains scannable
                          }}
            />,
          </Grid>
        </Grid>

      </Grid>
    </React.Fragment>
  );
}

export default Marketing;
